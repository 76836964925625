function Sliders() {
    let SlidersArray = []

    let sliders = document.querySelectorAll(".slider")
    sliders.forEach((slider,index) => {
        slider.setAttribute("data-slider-id",index)
        let isLoop = slider.classList.contains("_loop")

        SlidersArray[index] = new Swiper(`.slider[data-slider-id="${index}"] .swiper-container`, {
            slidesPerView: "auto",
            spaceBetween: 0,
            watchSlidesProgress: true,
            preventClicks :true,
            a11y: false,
            observer: true,
            observeParents: true,
            observeSlideChildren: true,
            loop: isLoop,
            navigation: {
                nextEl: `.slider[data-slider-id="${index}"] .swiper-button-next`,
                prevEl: `.slider[data-slider-id="${index}"] .swiper-button-prev`,
            },
            pagination: {
                el: `.slider[data-slider-id="${index}"] .swiper-pagination`,
                type: 'bullets',
                clickable: true,
            },
        })
    })
}

function newsGallerySlider() {
    var swiper = new Swiper('.news-gallery .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
        navigation: {
            nextEl: '.news-gallery .swiper-button-next',
            prevEl: '.news-gallery .swiper-button-prev',
        },
        pagination: {
            el: '.news-gallery .swiper-pagination',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                return `<span class="_current">${current}</span>` + '/' + (total);
            }
        },
    })
}

function categoriesSlider() {
    var swiper = new Swiper('.categories .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
        navigation: {
            nextEl: '.categories .swiper-button-next',
            prevEl: '.categories .swiper-button-prev',
        },
        pagination: {
            el: '.categories .swiper-pagination',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                return `<span class="_current">${current}</span>` + '/' + (total);
            }
        },
    })
}

function equipmentSlider() {
    var swiper = new Swiper('.equipment-slider .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 0,
        navigation: {
            nextEl: '.equipment-slider .swiper-button-next',
            prevEl: '.equipment-slider .swiper-button-prev',
        },
        pagination: {
            el: '.equipment-slider .swiper-pagination',
            type: 'custom',
            renderCustom: function (swiper, current, total) {
                return `<span class="_current">${current}</span>` + '/' + (total);
            }
        },
    })
}


Sliders()
newsGallerySlider()
categoriesSlider()
equipmentSlider()
